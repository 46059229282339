import { MDBCard, MDBCol, MDBRow } from 'mdbreact'

import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const centers = (props) => {
  return (
    <>
      {props.exporttype == 'services' || true ? (
        <>
          <h5 className="white-text-Genesis shadow-Genesis">Services</h5>

          <ul>
            {props.services.map((services, sKey) => {
              let resultURL = ''

              let resultService = services.name
              //  check if it is a `No Service`
              if (
                services.id === `d055c173-114a-517c-8a44-f6cd28dd8f44` ||
                services.name.toLowerCase() === `no service`
              ) {
                resultService = ``
              } else {
                //  check if there is a link to the page
                if (props.linkedItems) {
                  props.linkedItems.map((linkedItem, sPathKey) => {
                    if (linkedItem.node.id === services.id) {
                      resultURL = linkedItem.node.field_service_page_link

                      resultService = (
                        <a
                          key={sPathKey}
                          href={linkedItem.node.field_service_page_link}
                        >
                          {services.name}
                        </a>
                      )
                    }
                  })
                }
              }
              resultService = (
                <li exportkey={`service-${sKey + 1}`} key={sKey}>
                  {resultService}
                  {resultURL == '' ? '' : ',' + resultURL}
                </li>
              )
              return resultService
            })}
          </ul>
        </>
      ) : null}

      {props.exporttype == 'amenities' || true ? (
        <>
          <h5 className="white-text-Genesis shadow-Genesis">Amenities</h5>
          <ul>
            {props.amenities.map((amenities, aKey) => {
              let resultURL = ''
              let resultAmenity = amenities.name

              //  check if it is a `No Amenity`
              if (
                amenities.id === `55ded130-d505-5aef-9e5f-e00c7ede7cd7` ||
                amenities.name.toLowerCase() === `no amenity`
              ) {
                resultAmenity = ``
              } else {
                //  check if there is a link to the page
                if (props.linkedItems) {
                  props.linkedItems.map((linkedItem, aPathKey) => {
                    if (linkedItem.node.id === amenities.id) {
                      resultURL = linkedItem.node.field_service_page_link
                      resultAmenity = (
                        <a
                          key={aPathKey}
                          href={linkedItem.node.field_service_page_link}
                        >
                          {amenities.name}
                        </a>
                      )
                    }
                  })
                }
              }
              resultAmenity = (
                <li exportkey={`amenity-${aKey + 1}`} key={aKey}>
                  {resultAmenity}
                  {resultURL == '' ? '' : ',' + resultURL}
                </li>
              )
              return resultAmenity
            })}
          </ul>
        </>
      ) : null}
    </>
  )
}

export default centers
