import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import InlineBodyTransform from '../inlinebodytransform'

import React from 'react'
import StaffCard from './staffcard'

const StaffSection_export = (props) => {
  //console.log(props)
  const members = props.paragraph.relationships.field_staff_member

  return members.map((member, key) => {
    let imageStaff = ''
    if (member.relationships.field_member_photo) {
      imageStaff =
        member.relationships.field_member_photo.localFile.childImageSharp
          .original.src
    }
    return (
      <div id={`Staff${key}`} key={`Staff${key}`}>
        {imageStaff ? (
          <img
            exportkey={`staff-${key}-photo`}
            className="img-fluid"
            src={encodeURI(imageStaff)}
            alt={member.field_name}
            height={50}
            width={50}
          />
        ) : null}
        <h5 exportkey={`staff-${key}-name`}>{member.field_name}</h5>
        <span exportkey={`staff-${key}-title`}>{member.field_sub_title}</span>
        <div exportkey={`staff-${key}-info`}>
          <InlineBodyTransform bodyValue={member.field_description} />
        </div>
      </div>
    )
  })
}

export default StaffSection_export
