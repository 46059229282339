import { MDBCol, MDBRow } from 'mdbreact'

import React from 'react'
import Stars from './ratings/centerstars'
import styled from 'styled-components'

import goodFaithEstimateImage from './images/GoodFaithEstimateImage.png'

const Img = styled.img`
  max-width: 3.5em;
`

const TopCenter_export = (props) => {
  const post = props.post
  const awards = post.relationships.field_awards_and_affiliations
  console.log(props)
  return (
    <>
      <h1>Name & Address</h1>
      <table>
        <thead>
          <tr>
            <th exportkey="centerinfo-0-name">Name</th>
            <th exportkey="centerinfo-0-fulladdress">Full Address</th>
            <th exportkey="centerinfo-0-address">Address</th>
            <th exportkey="centerinfo-0-city">City</th>
            <th exportkey="centerinfo-0-statecode">State Code</th>
            <th exportkey="centerinfo-0-latitude">Latitude</th>
            <th exportkey="centerinfo-0-longitude">Longitude</th>
            <th exportkey="centerinfo-0-mainphone">Main Phone</th>
            <th exportkey="centerinfo-0-facebookId">Facebook ID</th>
            <th exportkey="centerinfo-0-alias">Alias</th>
            <th exportkey="centerinfo-0-video">Video</th>
            <th exportkey="centerinfo-0-admissionsemail">Admissions Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td exportkey="centerinfo-1-name">{post.title}</td>
            <td exportkey="centerinfo-1-fulladdress">{props.address}</td>
            <td exportkey="centerinfo-1-address">{post.field_address_fl}</td>
            <td exportkey="centerinfo-1-city">{post.field_city}</td>
            <td exportkey="centerinfo-1-statecode">{post.field_state}</td>
            <td exportkey="centerinfo-1-latitude">{post.field_latitude}</td>
            <td exportkey="centerinfo-1-longitude">{post.field_longitude}</td>
            <td exportkey="centerinfo-1-mainphone">{props.mainphone}</td>
            <td exportkey="centerinfo-1-facebookId">
              {post.field_facebook_id}
            </td>
            <td exportkey="centerinfo-1-alias">{post.path.alias}</td>
            <td exportkey="centerinfo-1-video">{post.field_bri}</td>
            <td exportkey="centerinfo-1-admissionsemail">
              {post.field_center_email}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <h1>
          <a>Awards and Affiliations</a>
        </h1>
        <div id="awards">
          <table>
            <thead>
              <tr>
                <th exportkey="awards-0-name">Name</th>
                <th exportkey="awards-0-link">Link</th>
                <th exportkey="awards-0-image">Image</th>
                <th exportkey="awards-0-imageUrl">Image URL</th>
              </tr>
            </thead>
            <tbody>
              {' '}
              {awards.map((award, aKey) => (
                <tr>
                  <td exportkey={`awards-${aKey + 1}-name`}>{award.name}</td>
                  <td exportkey={`awards-${aKey + 1}-link`}>
                    {award.field_service_page_link}
                  </td>
                  <td exportkey={`awards-${aKey + 1}-image`}>
                    Image:
                    {
                      award.relationships.field_logo_image.localFile
                        .childImageSharp.original.src
                    }
                  </td>
                  <td exportkey={`awards-${aKey + 1}-imageUrl`}>
                    <Img
                      src={
                        award.relationships.field_logo_image.localFile
                          .childImageSharp.original.src
                      }
                      alt={award.name}
                      title={award.name}
                      height={25}
                      width={25}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </>
  )
}

export default TopCenter_export
