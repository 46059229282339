import { MDBCol, MDBIcon, MDBRow } from 'mdbreact'

import InlineBodyTransform from '../../components/inlinebodytransform'
import React from 'react'

const MiddleCenter_export = (props) => {
  const post = props.post
  const featuredItems = post.relationships.field_services

  return (
    <>
      <h1>Desc</h1>
      <table>
        <thead>
          <tr>
            <th>Desc</th>
            <th>Google Map Link</th>
            <th>Phone</th>
            <th>Fax</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td exportkey="centerinfo-1-desc">
              <InlineBodyTransform bodyValue={post.body} />
            </td>
            <td exportkey="centerinfo-1-googlemaplink">
              {props.dirGoogleLink}
            </td>
            <td exportkey="centerinfo-1-phone">
              {post.field_telephone_number}
            </td>
            <td exportkey="centerinfo-1-fax">{post.field_fax_number}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <h1>Featured Items</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {featuredItems.map((item, key) => {
            if (item.field_featured) {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.field_service_page_link}</td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </>
  )
}

export default MiddleCenter_export

const fontSizeP = { fontSize: '0.9rem' }
const borderColor = { borderColor: '#ee1C25!important' }
