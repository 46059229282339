import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { MDBCard, MDBCol, MDBContainer, MDBRow } from 'mdbreact'

import BreadcrumbMenu from '../../../components/menus/centerbcmenu'
import CClistScript from '../../../components/centers/closercenters/cclistscript'
import Carousel from '../../../components/centers/carouselcenter'
import Carousel_export from '../../../components/centers/carouselcenter_export'
import CloserCenters from '../../../components/centers/closercenters/cclist'
import Events from '../../../components/happenings/events'
import InlineBodyTransform from '../../../components/inlinebodytransform'
import Layout from '../../../components/layout'
import MiddleCenter from '../../../components/centers/middlecenter'
import MiddleCenter_export from '../../../components/centers/middlecenter_export'
import News from '../../../components/happenings/news'
import FinancialStatement from '../../../components/financialstatement/financialstatement'
import Ratings from '../../../components/centers/ratings/ratings'
import React from 'react'
import RightBox from '../../../components/menus/right_box'
import SectionContentSA_export from '../../../components/centers/servicesamenities_export'
import SeoCenters from '../../../components/centers/seocenters'
import StaffSection from '../../../components/centers/staffsection'
import StaffSection_export from '../../../components/centers/staffsection_export'
import TopCenter from '../../../components/centers/topcenter'
import TopCenter_export from '../../../components/centers/topcenter_export'
import Updates from '../../../components/updates/updates'
import Videobri from '../../../components/centers/videobri'
import Videobri_export from '../../../components/centers/videobri_export'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

let gmdv = require(`../../../ghc_config`)

const Detail = ({ data, location }) => {
  const post = data.nodeCenter
  const HappeningsEvents = data.allNodeHappenings
  const HappeningsNews = data.allNodeCentersHappeningsNews
  const linkedItems = data.linkedItems
  const allPracticemaxRatings = data.allPracticemaxRatings
  const allPracticemaxComments = data.allPracticemaxComments

  const ref = React.useRef()

  //  Start Position for closer Centers
  let startPosition = {}
  if (post.field_latitude && post.field_longitude) {
    startPosition = {
      lat: Number(post.field_latitude),
      lng: Number(post.field_longitude),
    }
  }

  /*  google destination https://developers.google.com/maps/documentation/urls/guide */
  let address = `${post.field_address_fl.trim()}, `
  address += `${post.field_city}, ${gmdv.selectState(post.field_state).spa} ${
    post.field_zip
  }`

  let mainphone =
    post.field_telephone_number == null ? '' : post.field_telephone_number
  let admissionsphone =
    post.field_admissions_phone == null ? '' : post.field_admissions_phone
  let admissionsefax =
    post.field_admissions_efax == null ? '' : post.field_admissions_efax

  let destination = `${post.title}, ${address}`
  let dirGoogleLink = `https://www.google.com/maps/dir/?api=1`
  dirGoogleLink += `&destination=${encodeURI(destination)}`
  if (post.field_google_place_id) {
    dirGoogleLink += `&destination_place_id=${post.field_google_place_id}`
  }

  const financialstatementlistInactive = {
    arborglen: [
      'Arbor_Glen_Medicare_cost_report_2021-12-31.pdf',
      'Arbor_Glen_Financial_Stmts_12-31-21.pdf',
    ],
    cranbury: [
      'Cranbury_Medicare_cost_report_2021-12-31.pdf',
      'Cranbury_Financial_Stmts_12-31-21.pdf',
    ],
    hollymanor: [
      'Holly_Manor_Medicare_cost_report_2021-12-31.pdf',
      'Holly_Manor_Financial_Stmts_12-31-21.pdf',
    ],
    lopatcong: [
      'Lopatcong_Medicare_cost_report_2021-12-31.pdf',
      'Lopatcong_Financial_Stmts_12-31-21.pdf',
    ],
    jerseyshore: [
      'Jersey_Shore_Medicare_cost_report_2021-12-31.pdf',
      'Jersey_Shore_Financial_Stmts_12-31-21.pdf',
    ],
    northcape: [
      'North_Cape_Medicare_cost_report_2021-12-31.pdf',
      'North_Cape_Financial_Stmts_12-31-21.pdf',
    ],
    mapleglen: [
      'Maple_Glen_Medicare_cost_report_2021-12-31.pdf',
      'Maple_Glen_Financial_Stmts_12-31-21.pdf',
    ],
    millville: [
      'Millville_Medicare_cost_report_2021-12-31.pdf',
      'Millville_Financial_Stmts_12-31-21.pdf',
    ],
    ridgewoodnj: [
      'Ridgewood_Medicare_cost_report_2021-12-31.pdf',
      'Ridgewood_Financial_Stmts_12-31-21.pdf',
    ],
    southernocean: [
      'Southern_Ocean_Medicare_cost_report_2021-12-31.pdf',
      'Southern_Ocean_Financial_Stmts_12-31-21.pdf',
    ],
    troyhills: [
      'Troy_Hills_Medicare_cost_report_2021-12-31.pdf',
      'Troy_Hills_Financial_Stmts_12-31-21.pdf',
    ],
  }
  const financialstatementlist = {
    arborglen: [
      'Arbor_Glen_Medicare_cost_report_2022-12-31.pdf',
      'Arbor_Glen_Financial_Stmts_12-31-22.pdf',
    ],
    cranbury: [
      'Cranbury_Medicare_cost_report_2022-12-31.pdf',
      'Cranbury_Financial_Stmts_12-31-22.pdf',
    ],
    hollymanor: [
      'Holly_Manor_Medicare_cost_report_2022-12-31.pdf',
      'Holly_Manor_Financial_Stmts_12-31-22.pdf',
    ],
    lopatcong: [
      'Lopatcong_Medicare_cost_report_2022-12-31.pdf',
      'Lopatcong_Financial_Stmts_12-31-22.pdf',
    ],
    jerseyshore: [
      'Jersey_Shore_Medicare_cost_report_2022-12-31.pdf',
      'Jersey_Shore_Financial_Stmts_12-31-22.pdf',
    ],
    northcape: [
      'North_Cape_Medicare_cost_report_2022-12-31.pdf',
      'North_Cape_Financial_Stmts_12-31-22.pdf',
    ],
    mapleglen: [
      'Maple_Glen_Medicare_cost_report_2022-12-31.pdf',
      'Maple_Glen_Financial_Stmts_12-31-22.pdf',
    ],
    millville: [
      'Millville_Medicare_cost_report_2022-12-31.pdf',
      'Millville_Financial_Stmts_12-31-22.pdf',
    ],
    ridgewoodnj: [
      'Ridgewood_Medicare_cost_report_2022-12-31.pdf',
      'Ridgewood_Financial_Stmts_12-31-22.pdf',
    ],
    southernocean: [
      'Southern_Ocean_Medicare_cost_report_2022-12-31.pdf',
      'Southern_Ocean_Financial_Stmts_12-31-22.pdf',
    ],
    troyhills: [
      'Troy_Hills_Medicare_cost_report_2022-12-31.pdf',
      'Troy_Hills_Financial_Stmts_12-31-22.pdf',
    ],
  }

  return (
    <>
      <div>
        <TopCenter_export
          post={post}
          location={location}
          allPracticemaxRatings={allPracticemaxRatings}
          address={address}
          mainphone={mainphone}
          admissionsefax={admissionsefax}
          admissionsphone={admissionsphone}
        />
      </div>
      <div>
        <MiddleCenter_export post={post} dirGoogleLink={dirGoogleLink} />
      </div>

      <div>
        <h1>Photos</h1>
        <table>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {post.relationships.field_image_carousel[0] ? (
              <tr>
                {post.relationships.field_image_carousel.map(
                  (image_carousel, aKey) => {
                    //console.log(image_carousel)
                    return (
                      <td exportkey={`photos-${aKey + 1}-image`}>
                        <img
                          width={100}
                          src={encodeURI(
                            image_carousel.localFile.childImageSharp.original
                              .src
                          )}
                        />
                      </td>
                    )
                  }
                )}
              </tr>
            ) : (
              ''
            )}
          </tbody>
        </table>
      </div>

      <div>
        <h1>Video</h1>
        <div>
          {post.field_bri ? (
            <InlineBodyTransform bodySearch={post.field_bri} />
          ) : (
            ''
          )}
        </div>
      </div>

      <div>
        <h1>Service & Amenities</h1>
        <div>
          {(post.relationships.field_services &&
            (post.relationships.field_services[0].id !==
              `d055c173-114a-517c-8a44-f6cd28dd8f44` ||
              post.relationships.field_services[0].name.toLowerCase() !==
                `no service`)) ||
          (post.relationships.field_amenities &&
            (post.relationships.field_amenities[0].id !==
              `55ded130-d505-5aef-9e5f-e00c7ede7cd7` ||
              post.relationships.field_amenities[0].name.toLowerCase() !==
                `no amenity`)) ? (
            <SectionContentSA_export
              services={post.relationships.field_services}
              amenities={post.relationships.field_amenities}
              linkedItems={linkedItems.edges}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        <h1>Staff</h1>
        <div>
          <StaffSection_export paragraph={post} />
        </div>
      </div>
      <div>
        <h1>Events</h1>
        <div>
          {' '}
          {HappeningsEvents.edges.length > 0 ? (
            <Events EventList={HappeningsEvents} name={post.title} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        <h1>News</h1>
        <div>
          {' '}
          {HappeningsNews.edges.length > 0 ? (
            <News EventList={HappeningsNews} name={post.title} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        <h1>Center Financial Statement</h1>
        <div>
          {' '}
          {financialstatementlist[post.path.alias.replace('/', '')] !==
            undefined &&
          financialstatementlistInactive[post.path.alias.replace('/', '')] !==
            undefined ? (
            <FinancialStatement
              financialStatementUrls={
                financialstatementlist[post.path.alias.replace('/', '')]
              }
              financialStatementInactiveUrls={
                financialstatementlistInactive[post.path.alias.replace('/', '')]
              }
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        <h1>Outcomes</h1>
        <div
          ref={ref}
          onClick={() => {
            //console.log(ref.current?.innerHTML)
            const blobInput = new Blob([ref.current?.innerHTML], {
              type: 'text/html',
            })
            const clipboardItemInput = new ClipboardItem({
              'text/html': blobInput,
            })
            navigator.clipboard.write([clipboardItemInput])
          }}
        >
          {post.field_outcomes ? (
            <InlineBodyTransform bodyValue={post.field_outcomes} />
          ) : (
            ''
          )}
        </div>
      </div>

      <div>
        <h1></h1>
        <div>
          <textarea style={{ width: '100%' }}>{JSON.stringify(post)}</textarea>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query(
    $id: String!
    $alias: String!
    $pAlias: String!
    $today: Date!
    $tyago: Date!
  ) {
    nodeCenter(id: { eq: $id }) {
      id
      title
      field_address_fl
      field_city
      field_state
      field_zip
      field_telephone_number
      field_fax_number
      body {
        value
      }
      created
      field_outcomes {
        value
      }
      path {
        alias
      }
      field_google_place_id
      field_facebook_id
      field_latitude
      field_longitude
      field_bri
      field_vaccinated_staff
      field_vaccinated_residents
      field_admissions_efax
      field_admissions_phone
      field_center_email
      #field_visitation_override_exp(formatString: "MM/DD/YYYY", locale: "en-US")
      #field_test_frequency_overide_exp(formatString: "MM/DD/YYYY", locale: "en-US")
      relationships {
        field_image_carousel {
          localFile {
            name
            ino
            id
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        field_staff_member {
          field_name
          field_sub_title
          relationships {
            field_member_photo {
              localFile {
                childImageSharp {
                  member_photo: resize(width: 100, height: 120) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
          field_description {
            value
          }
        }
        field_services {
          id
          name
          field_featured
          field_service_page_link
          path {
            alias
          }
        }
        field_amenities {
          id
          name
          path {
            alias
          }
        }
        field_awards_and_affiliations {
          id
          name
          field_service_page_link
          relationships {
            field_logo_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    # Events
    allNodeHappenings(
      filter: {
        relationships: { field_center: { path: { alias: { eq: $alias } } } }
        field_happening_s_date: { gte: $today }
      }
      sort: { fields: field_happening_s_date, order: DESC }
    ) {
      edges {
        node {
          field_happening_s_date
          title
          path {
            alias
          }
          relationships {
            field_center {
              title
            }
          }
        }
      }
    }
    # News
    allNodeCentersHappeningsNews(
      filter: {
        relationships: { field_center: { path: { alias: { eq: $alias } } } }
        field_happening_s_date: { gte: $tyago }
      }
      sort: { fields: field_happening_s_date, order: DESC }
    ) {
      edges {
        node {
          field_happening_s_date
          title
          path {
            alias
          }
          relationships {
            field_center {
              title
            }
          }
        }
      }
    }
    linkedItems: allTaxonomyTermServices(
      filter: { field_service_page_link: { ne: null } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          field_service_page_link
        }
      }
    }

    #centerStarsRatings
    allPracticemaxRatings {
      totalCount
      edges {
        node {
          shortName
          id
          medicalOrganizationEntity {
            name
            aggregateRating {
              _type
              bestRating
              ratingCount
              ratingValue
              worstRating
            }
          }
        }
      }
    }

    #Comments
    allPracticemaxComments(filter: { internal: { content: { eq: $pAlias } } }) {
      totalCount
      edges {
        node {
          ratingValue
          reviewDate
          authorType
          reviews {
            question
            answer
          }
          internal {
            content
          }
        }
      }
    }
  }
`

export default Detail
