import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import InlineBodyTransform from '../inlinebodytransform'
import React from 'react'
import styled from 'styled-components'

const AccordionComp = styled(Accordion)`
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const VirtualTour = (props) => {}

export default VirtualTour
